#app-luuucky { display: flex; flex-direction: column; overflow-x: hidden; position: relative; width: 100%; font-weight:800; min-height: 100vh; font-family: "proxima-nova",sans-serif;}
#app-luuucky nav {display:none;}
#app-luuucky .auto-hide {display:none;}

#app-luuucky .header_text {display:flex; flex-direction: column; justify-content: end; flex: 1 1 100%; padding-top: 60px;}

#app-luuucky .footer {flex: 1 1 100%; display: flex; flex-direction: column; justify-content: end; padding: 20px 0; font-weight: normal; color: rgba(255,255,255,0.5); text-align: center;}
#app-luuucky.active .footer {display: none;}

#app-luuucky.active { padding-top:0; padding-bottom:0; }

#app-luuucky.active nav { display:block;}
#app-luuucky.active #options { display:none;}
#app-luuucky.active .padded { display:none;}
#app-luuucky.active #screen { display:block;}
#app-luuucky.active #main { display:block;}
#app-luuucky.active .auto-hide {display:block; transition: all 0.25s ease;}

#app-luuucky #finished { width:100%; height:100%; top:0px; position:absolute; margin:0px; padding:0px; overflow:hidden; display:none; }
#app-luuucky #finished table{ height:100%; width:100%;}

#app-luuucky #screen { width:100%; height:100%; top:0px; position:absolute; margin:0px; padding:0px; overflow:hidden; display:none; transition: all 0.5s ease; opacity: 0;}
#app-luuucky #screen table{ height:100%; width:100%;}

#app-luuucky-message { display:none; background:rgba(0,0,0,0.3); z-index:10; position:absolute; width:100%; padding:0.5em 0; font-family: "proxima-nova",sans-serif; font-size:1.5em; text-align:center;  }
#app-luuucky-offline { display:none; color:rgba(0,0,0,0.1); z-index:10; position:absolute; top:0; padding:0.5em; font-family: "Verdana","proxima-nova",sans-serif; font-size:11px; font-weight:bold;}

#app-luuucky .details p {font-size:2em;}
#app-luuucky a.icon { width:75px; height:75px; background:url('./img/luuucky-spirit.png') 0 0; display:inline-block; border:none;}
#app-luuucky a.icon + a.icon { margin-left:20px;}
#app-luuucky a.icon.settings { background-position:0 -75px; }
#app-luuucky a.icon.fullscreen { background-position:0 -150px;}
#app-luuucky a.icon.colors { background-position:0 -225px; }

#app-luuucky #log-trigger { -webkit-transform:rotate(270deg); font-weight:bold; position:absolute; top:50%; left:-75px; height:50px; line-height:50px;width:100px; text-align:center;  background:rgba(0,0,0,0.3); cursor:pointer; text-transform:uppercase }
#app-luuucky #log .wrap {  position:absolute;  width:96%; bottom:0; top:0; padding:2%; background:rgba(0,0,0,0.3); right:0; }
#app-luuucky.active #log {display:block;}
#app-luuucky #log {transition: transform 0.25s ease ; display:none; right:-20%; top:0; position:absolute; width:20%;  margin:0; padding:0; bottom:0; font-size:2em; font-weight:normal; -webkit-transform: right 0.3s ease-out; transform: right 0.3s ease-out;}
#app-luuucky #log span { color:rgba(0,0,0,0.3) }
#app-luuucky #log h2 { font-size:2em;}
#app-luuucky h1 + h2 { margin:0;}
#app-luuucky.active.loggy #screen  { width:79%;}
#app-luuucky.active.loggy #finished  { width:79%;}

#app-luuucky.active.loggy #log  { transform: translateX(-100%);}
#app-luuucky.active.loggy .cornor-bottom-right ,
#app-luuucky.active.loggy .cornor-top-right { right:21%;}

#app-luuucky .cornor-bottom-right { position:absolute; right:0; bottom:0; text-align:right; z-index:5;}
#app-luuucky .cornor-top-right { position:absolute; right:0; top:0; text-align:right; z-index:5;}
#app-luuucky .cornor-bottom-left { position:absolute; left:0; bottom:0; text-align:left; z-index:5;}


#app-luuucky .cornor-bottom-left p { font-size:1.5em; padding:20px; color:rgba(0,0,0,0.3)}
#app-luuucky .cornor-bottom-left p { font-size:1.5em; padding:20px; color:rgba(0,0,0,0.3)}


#app-luuucky #the_number { position:relative; font-size: 30vw;  text-shadow: 7px 7px 0px rgba(0,0,0,0.3); font-weight:bold; color:#FFF; -webkit-touch-callout: none; -webkit-user-select: none;-khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
#the_number .book-nr:before {content:attr(title); font-size:0.1em; line-height:3em; text-shadow: none; color:rgba(0,0,0,0.3); position:absolute;}
#the_number .ticket-nr:before {content:attr(title); font-size:0.1em; line-height:3em; text-shadow: none; color:rgba(0,0,0,0.3); position:absolute;}

#app-luuucky form {overflow:auto;}
#app-luuucky .info {line-height:150%; margin-top:40px;}
#app-luuucky #options { font-size:4em; font-weight:bold; color:#FFF; line-height:150%;width:100%; height:100%; top:0px; margin:0px; padding:0px; overflow:hidden;}
#app-luuucky #options table{ height:100%;}

#app-luuucky label { background:#FFF; height:100px; line-height:100px;  color:#CCC; text-transform:uppercase; padding:0 25px; font-size:1em; float:left;}
#app-luuucky label.right-label { border-left:1px solid #E5E5E5;}

#app-luuucky input { padding:0;float:left; height:100px; line-height:100px; width:200px; color:#00D069; border:none; border-left:solid 1px #E5E5E5; font-size:1em; text-align:center; font-weight:bold; font-weight:800; font-family: "proxima-nova",sans-serif; -webkit-appearance: none; appearance: none; -moz-appearance: none; -ms-appearance:none; border-radius:0;}
#app-luuucky input#from { margin-right:40px; }
#app-luuucky input#limit { margin-right:40px; }

div.no-wrap { white-space:nowrap; display:inline-block;} 

#app-luuucky input:focus { outline:none;  outline-offset:0px;}


#app-luuucky button { font-family: "proxima-nova",sans-serif; font-weight:800; background:rgba(0,0,0,0.4); height:100px; line-height:100px; border:none; color:#FFF; border-radius:8px; font-size:1em; text-align:center;  padding:0 30px; vertical-align:bottom; margin-top:1em;}
#app-luuucky button:hover{ background:#f3e815; color:#333; }		

#app-luuucky.numeral .on_numeral { display:auto; }
#app-luuucky.numeral .on_books { display:none; }
#app-luuucky.books .on_numeral { display:none; }
#app-luuucky.books .on_books { display:auto; }


.fadeIn {animation: fadeIn 0.5s forwards;}
.fadeOut {animation: fadeOut 0.5s forwards;}
@keyframes fadeIn {
  from { opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeOut {
  from { opacity: 1;}
  to {opacity: 0;}
}

@media screen and (max-width: 1300px) {

}


@media screen and (max-width: 1200px) {
	#app-luuucky label {  height:70px; line-height:70px;  margin-top: 30px; }
	#app-luuucky input {  height:70px; line-height:70px; width:160px;  margin-top: 30px; }
	#app-luuucky input#from { margin-right:40px; }
	#app-luuucky input#limit { margin-right:40px; }
	#app-luuucky button{ height:70px; line-height:70px; margin-top: 30px; }

}

@media screen and (max-width:1024px) {
	#app-luuucky label {  height:60px; line-height:60px;  }
	#app-luuucky input {  height:60px; line-height:60px; width:140px; font-size:0.8em; }
	#app-luuucky input#from { margin-right:0; }
	#app-luuucky input#limit { margin-right:0; }
	#app-luuucky button{ height:60px; line-height:60px;  display:block;}
}

#app-luuucky {
	-webkit-touch-callout: none; /* iOS Safari */
	  -webkit-user-select: none; /* Safari */
	   -khtml-user-select: none; /* Konqueror HTML */
		 -moz-user-select: none; /* Old versions of Firefox */
		  -ms-user-select: none; /* Internet Explorer/Edge */
			  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
  }