@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-Light.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-LightItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-Extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-Semibold.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-SemiboldItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-BoldIt.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-RegItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('../fonts/proxima-nova/ProximaNova-BoldIt.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}