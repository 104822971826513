html { margin:0; padding:0; color:#E5E5E5;}
body { margin:0; padding:0;  font-size:12px;}

h1,h2,h3,p ,a{font-weight:800; font-family: "proxima-nova",sans-serif; line-height:130%;}

h2,h3,p ,a{font-weight:100; font-family: "proxima-nova",sans-serif; }
h1 {font-size:6em; color:#FFF; text-transform:uppercase; margin:0; padding:0;}
h2 {font-size:3em;  color:rgba(0,0,0,0.4); text-transform:uppercase; margin:0; padding:0;}
h3 {font-size:1.3em; }
p { font-size:3em;  color:rgba(255,255,255,0.5); margin:0;}
h1 + h2{ margin-top:-15px; margin-bottom:60px;}

a:link, a:visited { color:#FFF; text-decoration:none; border-bottom:2px solid rgba(255,255,255,0.3) }


.padded { margin:0 auto; max-width:1300px; position:relative; width:85%; }

@media screen and (max-height: 800px) {
	.padded {width:75%; }
}

.slide {padding:10% 0; overflow:auto;}
.slide.top { padding-bottom:10%; }
.slide.top h1 {color:#2C3E51; margin-top:60px; }
.slide.top h2 {text-transform:none; }
.slide.top a:link, .slide.top a:visited { color:#2C3E51; text-decoration:none; border-bottom:2px solid rgba(0,0,0,0.1) }

.slide.center {text-align:center;}
.slide.footer { padding:30px;}

.slide.infoskjermen { }

.pitch { margin-left:55%;width:40%; max-width:800px;}


p.address { width:30%; margin-left:3%; float:left; font-size:2em; color:rgba(0,0,0,0.4);}
p.org ,p.contact { width:100%; text-align:center;font-size:2em; margin:60px 0; color:rgba(0,0,0,0.4);}
p.org span {font-size:.8em}

nav#stripes {  position:absolute; width:90%; top:160px; left:-200px; max-width:1600px;}
nav#stripes { -moz-transform:rotate(35deg); -ms-transform:rotate(35deg); -webkit-transform:rotate(35deg);  transform:rotate(35deg); }

nav#stripes a { border:none; width:50%; display:block; color:#FFF; text-align:right; text-decoration:none; text-transform:uppercase; font-size:2em; line-height:55px; height:55px; padding:0 25px 0 0 ; margin-top:20px; font-weight:800;}
nav#stripes a span { background:rgba(0,0,0,0.2); display:inline-block; float:left; width:55%; height:55px;}
nav#stripes a.nt-1 { width:45%;}
nav#stripes a.nt-2 { width:55%;}
nav#stripes a.nt-3 { width:65%;}
nav#stripes a.nt-4 { width:70%;}
nav#stripes a.nt-4 span { width:75%;}
nav#stripes a.nt-5 { width:55%;}
nav#stripes a.nt-5 span { width:75%;}

nav#stripes a.nt-6 { width:45%;}



nav#stripes a { -webkit-animation: bounce 0.5s ease-out;   }



.grey { background-color:#CCC }
.red { background-color:#EA4B36 }
.yellow { background-color:#F2C500 }
.dark-yellow { background-color:#B29100; }

.blue { background-color:#2D97DF }
.purple { background-color:#9D55BA }
.green {background-color:#00D069 }

.left img {max-width:100%;}
.left {width:50%; float:left; margin-right:5%;}
.right {width:45%; float:left;}

.playing-video .with-video .left {width: 80%; margin:auto;}
.playing-video .with-video .right {display:none;}


.blue .left {width:30%;}
.blue .right {width:60%;}

#video-holder {position:absolute; width:100%; height:100%; }
#video-holder iframe { width:100%; height:100%;}

.stop-video {display:none; } 

ul, li {margin:0; padding:0; list-style:none;}
ul.team {overflow:auto; width:100%;text-align:center;}
ul.team li {width:25%; text-align:center; display:inline-block; }
ul.team li img {}
ul.team li p {font-size:2em; }
ul.team li p.name {color:rgba(0,0,0,0.4); margin-top:20px;}
ul.team li p.email { }
ul.team li.clear {display:block; width:100%; height:40px;}


@-webkit-keyframes bounce {
  from 	{ -webkit-transform:translateX(-60%); opacity:1;   }
  to 	{ -webkit-transform:translateX(0); opacity:1; }
}

@media screen and (max-width: 1300px) {
	body {font-size:12px;}
	h1 + h2{ margin-top:-5px; }

}


@media screen and (max-width: 1200px) {
	body {font-size:11px;}

}

@media screen and (max-width:1024px) {

	body {font-size:10px;}
	.purple .left {width:50%;}
	.purple .right {width:45%; }

}

@media screen and (max-width:960px) {

	ul.team li {width:40%; margin-top:10%; }

	.left img {max-width:100%;}
	.left {width:100%; float:none; margin-right:0%;}
	.right {width:100%; float:none; margin-top:5%;}
	.purple .left {width:100%; }
	.purple .right {width:100%; }
	div.video {margin:auto;}
	
	.pitch { margin:auto; width:90%; max-width:inherit;margin-top:55%;}
	
	p.address { font-size:1.5em}
	nav#stripes { -webkit-transform:rotate(35deg);  position:absolute; width:140%; top:50px; left:-100px; max-width:1400px;}
	.slide.top { overflow:hidden; position:relative;}


}

@media screen and (max-width:750px) {

	.pitch {margin-top:65%;}

}

@media screen and (max-width:600px) {
.pitch {margin-top:75%;}

}
@media screen and (max-width:500px) {
.pitch {margin-top:105%;}

	ul.team li {width:100%; margin-top:10%; }

}

 
